<!--
 * @Author: xiaziwen
 * @Date: 2021-01-10 12:23:52
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-10 13:33:09
 * @FilePath: \crm-education\src\views\student\my_textbook_send\index.vue
-->
<template>
  <div class="live-page">
    <a-layout-header style="margin-bottom: 15px">
      <div class="header-bar">
        <div class="main-width">
          <a-row>
            <a-col :xs="{ span: 12 }">
              <div class="header-left d-flex align-center">
                <span style="font-size:28px;">直播列表</span>
              </div>
            </a-col>
            <a-col :xs="{ span: 12 }">
              <div class="header-right d-flex align-center justify-end">
                <a-dropdown>
                  <div class="header-user d-flex align-center link">
                     <a-avatar :size="24" icon="user" :src="user.studentImg"></a-avatar>
                     <span class="pl-5">{{ user.studentName }}</span>
                  </div>
                  <a-menu slot="overlay">
                    <a-menu-item>
                       <a href="javascript:;" @click="logout">退出</a>
                    </a-menu-item>
                    <!-- <a-menu-item>
                <a href="javascript:;">选项一</a>
              </a-menu-item> -->
                  </a-menu>
                </a-dropdown>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-layout-header>
    <a-row class="live-list">
      <a-col :span="6" class="live-list-col" v-for="o in data" :key="o.liveId" @click="handleClick(o)">
        <a-card hoverable>
          <div
            class="detail d-flex col align-center justify-center"
            :style="{ backgroundImage: 'url(' + (o.liveImg ? o.liveImg : '') + ')' }"
          > 
           <section v-show="isShow(o)">
            <p class="title fs-18 mb-15 fw-bold">{{ o.liveName }}</p>
            <p class="sub-author fs-14 fw-bold">主讲教师：{{ o.anchorTeacherName }}</p>
           </section>
          </div>
          <div class="meta fs-12" v-show="statusTxt(o)">{{ o.text }}</div>
          <a-card-meta class="text-left">
            <template slot="title">
              <p class="live-title fs-16 fw-bold">{{ o.liveName }}</p>
            </template>
            <template slot="description">
              <slot :options="o">
                <p class="author fs-14">主讲：{{ o.anchorTeacherName }}</p>
                <p class="date fs-14">时间：{{ o.liveStartTime }} ~ {{ o.liveEndTime }}</p>
              </slot>
            </template>
          </a-card-meta>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import api from '@/api'
import { mapState } from 'vuex'
import helper from '@/utils/helper'
export default {
  name: 'live_page',
  components: {},
  data() {
    return {
      channelNo: '',
      livePlatform: '',
      specialId: '',
      data: [],
      option: {}
    }
  },
  computed: {
    ...mapState(['userRole', 'noReadMsg', 'user'])
  },
  methods: {
    logout() {
      helper.clearUserInfo()
      this.$router.replace({ name: 'login_teacherLive' })
    },
    async getData() {
      let res = await api.student.getLiveChannelNo({ channelNo: this.channelNo,livePlatform: this.livePlatform,specialId:this.specialId })
      this.data = res.data
    },
    async handleClick(option) {
      const { liveState = '', channelNo = '', openType = '', recordVideoUrl = '', liveId = ''} = option
      if (liveState === '2') {
        this.$message.warning('直播未开始')
        return
      }
      if (liveState === '4') {
        this.$message.warning('未上课')
        return
      }

      if (this.livePlatform === '2' && !recordVideoUrl) {
          this.$message.warning('课程未导入直播地址')
          return
      }

      if (liveState === '1' || liveState === '3') {
        // this.$emit('entry', this.options)
        const { data = {} } = await api.student.getLiveUrl({ liveState, channelNo, openType, recordVideoUrl,liveId })
        if (data) {
          //window.location.href = data
            window.open(data, '_blank')
        } else {
          this.$message.warning('暂无直播地址')
        }
      }
    },
    isShow(option){
       if(option.liveImg){
         return false
       }
       return true
    },
    statusTxt(option) {
      const { liveState = '', countdown = '' } = option
      let text = ''
      if (liveState === '1' && Number(countdown) <= 0) {
        text = `直播进行中`
      } else if ((liveState === '2' || liveState === '1') && Number(countdown) > 0) {
        text = `即将开始（${countdown}小时之后）`
      } else if (liveState === '3') {
        text = '看回放'
      } else if (liveState === '4') {
        text = '未上课'
      }
      option.text = text
      return text
    }
  },
  beforeCreate() {},
  created() {
    this.channelNo = this.$route.query.channelNo
    this.livePlatform = this.$route.query.livePlatform
    this.specialId = this.$route.query.specialId
  },
  mounted() {
    this.getData()
  }
}
</script>
<style lang="scss">
.live-page {
  .live-list {
    max-width: 1200px;
    margin: 0 auto;
    .live-list-col {
      padding: 15px 15px;
      position: relative;
      .detail {
        height: 140px;
        background-color: #e74e3e;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        color: #fff;
      }
      .meta {
        position: absolute;
        right: 0;
        top: 0;
        background-color: #385dfb;
        color: #fff;
        padding: 2px 4px;
      }
      .hover-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.3);
        opacity: 0;
        transition: all 0.5s;
      }
      .ant-card-body {
        padding: 0 0;
      }
      .ant-card-meta-detail {
        padding: 10px 10px;
      }
    }
    img {
      height: 140px;
    }
  }
  .header-bar {
    height: 64px;
    .header-left {
      cursor: pointer;
    }
    .header-log {
      display: inline-block;
      width: 40px;
      height: 28px;
      background: url('../../../assets/img/logo.png') no-repeat center center;
    }

    .header-user {
      width: 100px;
    }
  }
  .panel-card {
    border-radius: 5px;
    overflow: hidden;
    .ant-card-body {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 20px;
    }
    .live-title {
      padding-top: 20px;
    }
    &:hover {
      .live-title {
        color: #385dfb;
      }
      .hover-mask {
        opacity: 1;
      }
    }
    .author,
    .date {
      padding-bottom: 5px;
    }
  }
}
</style>
